export enum VideoFeedEvent {
  BeginRequesting,
  Received,
  Playing,
}

export enum VideoControlsAction {
  Play,
  Pause,
  Backward10,
  Forward10,
  Rewind,
  FastForward,
  Fullscreen,
}
