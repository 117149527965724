import { Marker, StaticMap } from 'react-map-gl'

export interface StaticMapBoxIProps {
  /**
   * The latitude value for the map and map marker.
   */
  latitude: number
  /**
   * The longitude value for the map and map marker.
   */
  longitude: number
  /**
   * The height of the static map box view.
   */
  height?: string
  /**
   * The width of the static map box view.
   */
  width?: string
  /**
   * Will be the child of the `react-map-gl` `Marker` component.
   */
  children?: React.ReactNode
}

export default function StaticMapBox({
  height = '300px',
  width = '100%',
  latitude,
  longitude,
  children,
}: StaticMapBoxIProps) {
  return (
    <StaticMap
      mapStyle='mapbox://styles/mapbox/satellite-v9'
      width={width}
      height={height}
      latitude={latitude}
      longitude={longitude}
      zoom={11}
      mapboxApiAccessToken={process.env.NEXT_PUBLIC_MAPBOX_API_TOKEN}
    >
      {children && (
        <Marker
          latitude={latitude}
          longitude={longitude}
          offsetLeft={-18}
          offsetTop={-18}
        >
          {children}
        </Marker>
      )}
    </StaticMap>
  )
}
