import NextHead from 'next/head'

interface IHeadProps {
  metaTitle?: string
  metaSubTitle?: string
  metaKeywords?: string
  metaDescription?: string
  ogImage?: string
}

export default function Head({
  metaTitle = 'HiveWatch',
  metaSubTitle,
  metaKeywords = 'HiveWatch',
  metaDescription = 'The first ever Security Fusion Platform',
  ogImage = 'ogImage.jpeg',
}: IHeadProps) {
  return (
    <NextHead>
      <title>
        {metaSubTitle ? `${metaTitle} • ${metaSubTitle}` : metaTitle}
      </title>
      <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      {metaDescription && <meta name='description' content={metaDescription} />}
      {metaKeywords && <meta name='keywords' content={metaKeywords} />}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@HiveWatch' />
      <meta name='twitter:creator' content='@HiveWatch' />
      {metaTitle && <meta name='twitter:title' content={metaTitle} />}
      {metaDescription && (
        <meta name='twitter:description' content={metaDescription} />
      )}
      <meta property='og:site_name' content='HiveWatch' />
      {ogImage && (
        <meta
          property='twitter:image'
          content={`https://hivewatch.com/${ogImage}`}
        />
      )}
      <link
        rel='preload'
        href='/fonts/EuclidSquare-Light.ttf'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/fonts/EuclidSquare-LightItalic.ttf'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/fonts/EuclidSquare-Regular.ttf'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/fonts/EuclidSquare-RegularItalic.ttf'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/fonts/EuclidSquare-Medium.ttf'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/fonts/EuclidSquare-MediumItalic.ttf'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/fonts/EuclidSquare-Semibold.ttf'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/fonts/EuclidSquare-SemiboldItalic.ttf'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/fonts/EuclidSquare-Bold.ttf'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/fonts/EuclidSquare-BoldItalic.ttf'
        as='font'
        crossOrigin=''
      />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/favicon/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon/favicon-16x16.png'
      />
      <link rel='manifest' href='/site.webmanifest' />
      <link
        rel='mask-icon'
        href='/favicon/safari-pinned-tab.svg'
        color='#5bbad5'
      />
      <meta name='msapplication-TileColor' content='#2b5797' />
      <meta name='theme-color' content='#000000' />
    </NextHead>
  )
}
