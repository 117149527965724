import { HYDRATE } from 'next-redux-wrapper'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { TimeFrame } from '@/utils/time'

import type {
  AppState,
  /*, AppThunk*/
} from '../store'

export interface TimeFrameState {
  totalActiveIncidents: TimeFrame
  totalIncidents: TimeFrame
}

const initialState: TimeFrameState = {
  totalActiveIncidents: TimeFrame.days1,
  totalIncidents: TimeFrame.days1,
}

export const timeFrameSlice = createSlice({
  name: 'timeFrame',
  initialState,
  reducers: {
    setTotalActiveIncidentTimeFrame: (
      state,
      action: PayloadAction<TimeFrame>
    ) => {
      state.totalActiveIncidents = action.payload
    },
    setTotalIncidentTimeFrame: (state, action: PayloadAction<TimeFrame>) => {
      state.totalIncidents = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      console.log('HYDRATE', state, action.payload)
      return {
        ...state,
        ...action.payload.subject,
      }
    },
  },
})

export const { setTotalActiveIncidentTimeFrame, setTotalIncidentTimeFrame } =
  timeFrameSlice.actions

export const selectTotalActiveIncidentsTimeFrame = (state: AppState) =>
  state.timeFrame.totalActiveIncidents
export const selectTotalIncidentsTimeFrame = (state: AppState) =>
  state.timeFrame.totalIncidents

export default timeFrameSlice.reducer
