import useAuth from '@/hooks/useAuth'
import { getOwnerIdFilter } from '@/hooks/useIncidentsFilters'
import { useDispatch, useSelector } from 'react-redux'

import ToggleButtonSwitch from '@/components/ui/ToggleButtonSwitch/ToggleButtonSwitch'
import {
  selectIncidentOwnerIdFilter,
  setIncidentOwnerIdFilter,
} from '@/redux/ui/uiSlice'

const IncidentsOwnerToggle = () => {
  const { me } = useAuth()
  const dispatch = useDispatch()
  const ownerIdFilterValue = useSelector(selectIncidentOwnerIdFilter)

  const onFilterAllIncidents = () => {
    dispatch(setIncidentOwnerIdFilter(null))
  }

  const onFilterMyIncidents = () => {
    dispatch(setIncidentOwnerIdFilter(getOwnerIdFilter(me?.id)))
  }

  return (
    <ToggleButtonSwitch
      height='40px'
      isLeftActive={ownerIdFilterValue === null}
      onLeftButtonClick={onFilterAllIncidents}
      onRightButtonClick={onFilterMyIncidents}
      activeBgColor='btn.100'
      activeTextColor='white'
      inactiveBgColor='white'
      inactiveTextColor='gray.800'
      leftButtonText='All'
      rightButtonText='Acknowledged by Me'
      leftArialLabel='show all incidents'
      rightArialLabel='show incidents assigned to me'
      size='sm'
      leftButtonDataTestId='incidentsPage_tableHeader_allButton'
      rightButtonDataTestId='incidentsPage_tableHeader_acknowledgedByMeButton'
    />
  )
}

export default IncidentsOwnerToggle
