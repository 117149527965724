import { createWrapper } from 'next-redux-wrapper'

import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import type { Persistor } from 'redux-persist'
import { createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import meReducer from './me/meSlice'
import { migrations } from './migrations'
import notificationsSlice from './notifications/notificationsSlice'
import timeFrameSlice from './timeFrame/timeFrameSlice'
import tokenReducer from './token/tokenSlice'
import uiReducer from './ui/uiSlice'

// defaults to localStorage for web

const persistConfig = {
  key: 'alpha-v1',
  version: 8,
  storage: storage,
  blacklist: ['ui'],
  whitelist: ['token'], // place to select which state you want to persist
  migrate: createMigrate(migrations, { debug: true }),
  //transforms: [emergencyCallBlacklist],
}

const uiPersistConfig = {
  key: 'ui',
  version: 8,
  storage: storage,
  blacklist: ['emergencyCall'],
  migrate: createMigrate(migrations, { debug: true }),
}

const rootReducer = combineReducers({
  me: meReducer,
  token: tokenReducer,
  ui: persistReducer(uiPersistConfig, uiReducer),
  timeFrame: timeFrameSlice,
  notification: notificationsSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const nonPersistedStore = configureStore({
  reducer: rootReducer,
})

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

/******************************************************************
 * TYPE HACKING
 * Abusing the final type to include the persistor with valid types
 */
type store = typeof store
type persistedStore = store & { __persistor?: Persistor }
const persistedStore: persistedStore = store
persistedStore.__persistor = persistStore(store)
/****************************************************************** */

export const wrapper = createWrapper<AppStore>(() => persistedStore)

export type AppStore = typeof persistedStore
export type AppState = ReturnType<AppStore['getState']>
export type AppDispatch = typeof persistedStore.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>
