import { BiDoorOpen, BiError, BiVideo } from 'react-icons/bi'
import { Column } from 'react-table'

import { Avatar, Badge, Text, Tooltip } from '@chakra-ui/react'

import { DeviceType, IncidentStatus } from '@/graphql/generated/schemas'
import formatDateTime, { formatTimeDistance } from '@/utils/formatDateTime'
import {
  getIncidentStatusColor,
  getIncidentStatusDisplayName,
} from '@/utils/incidents'

import IncidentGuardCell from './IncidentGuardCell'

type IncidentEvent = {
  date: string
  facilityName: string
  floor: string
  incidentName: string
  incidentStatus: React.ReactNode
  sensor: React.ReactNode
}

export function renderIncidentStatus(status: IncidentStatus) {
  return <Status status={status} />
}

export function Sensor({ sensor }: { sensor?: DeviceType }) {
  if (sensor === DeviceType.Door) {
    return <BiDoorOpen data-testid='sensor-door-cell' size={20} />
  }
  if (sensor === DeviceType.Camera) {
    return <BiVideo data-testid='sensor-camera-cell' size={20} />
  }
  return <BiError size={20} />
}

export function Status({ status }: { status?: IncidentStatus }) {
  return (
    <Badge
      rounded='md'
      px='2'
      bgColor={getIncidentStatusColor(status)}
      textTransform='capitalize'
      color='#fff'
      data-testid='incidentsPage_table_statusCell'
    >
      {getIncidentStatusDisplayName(status)}
    </Badge>
  )
}

export const columns: Column<IncidentEvent>[] = [
  {
    Header: 'INC ID',
    disableSortBy: false,
    accessor: 'displayId',
    Cell: ({ value }) => (
      <Tooltip
        hasArrow
        shouldWrapChildren
        label={value}
        placement='auto'
        openDelay={500}
      >
        <Text data-testid='incidentsPage_table_displayIdCell' isTruncated>
          {value}
        </Text>
      </Tooltip>
    ),
    width: 90,
  },
  {
    Header: 'Date',
    disableSortBy: false,
    accessor: 'date',
    Cell: ({ value }) => (
      <Tooltip
        hasArrow
        shouldWrapChildren
        label={`${formatDateTime(value)} • ${formatTimeDistance(value)}`}
        placement='auto'
        openDelay={500}
      >
        <Text data-testid='incidentsPage_table_dateCell' isTruncated>
          {formatDateTime(value)}
        </Text>
      </Tooltip>
    ),
    width: 150,
  },
  {
    Header: 'Name',
    disableSortBy: false,
    accessor: 'incidentName',
    Cell: ({ value }) => (
      <Tooltip
        hasArrow
        shouldWrapChildren
        label={value}
        placement='auto'
        openDelay={500}
      >
        <Text data-testid='incidentsPage_table_incidentNameCell' isTruncated>
          {value}
        </Text>
      </Tooltip>
    ),
    width: 200,
  },
  {
    Header: 'Facility',
    disableSortBy: false,
    accessor: 'facilityName',
    Cell: ({ value }) => {
      return (
        <Tooltip
          hasArrow
          shouldWrapChildren
          label={`${value?.name} • (${value?.shortName})`}
          placement='auto'
          openDelay={500}
        >
          <Text data-testid='incidentsPage_table_facilityNameCell' isTruncated>
            {value?.name}
          </Text>
        </Tooltip>
      )
    },
    width: 120,
  },
  {
    Header: 'Floor',
    disableSortBy: false,
    accessor: 'floor',
    Cell: ({ value }) => (
      <Tooltip
        hasArrow
        shouldWrapChildren
        label={value}
        placement='auto'
        openDelay={500}
      >
        <Text data-testid='incidentsPage_table_floorCell' isTruncated>
          {value}
        </Text>
      </Tooltip>
    ),
    width: 60,
  },
  {
    Header: 'Status',
    disableSortBy: false,
    accessor: 'incidentStatus',
    Cell: ({ value }) => (
      <Tooltip
        hasArrow
        shouldWrapChildren
        label={getIncidentStatusDisplayName(value)}
        placement='auto'
        openDelay={500}
      >
        {renderIncidentStatus(value)}
      </Tooltip>
    ),
    width: 80,
  },

  {
    Header: 'Sensor',
    disableSortBy: false,
    accessor: 'sensor',
    Cell: ({ value }) => (
      <Tooltip
        hasArrow
        shouldWrapChildren
        label={value?.toLowerCase() ?? 'No device'}
        textTransform='capitalize'
        placement='auto'
        openDelay={500}
      >
        <Sensor sensor={value} />
      </Tooltip>
    ),
    width: 65,
  },
  {
    Header: 'Operator',
    disableSortBy: false,
    accessor: 'operator',
    Cell: ({ value }) => (
      <Tooltip
        hasArrow
        shouldWrapChildren
        label={value ?? 'No operator'}
        textTransform='capitalize'
        placement='auto'
        openDelay={500}
      >
        <Avatar data-testid='operator-cell' size='xs' name={value} />
      </Tooltip>
    ),
    width: 80,
  },
  {
    Header: 'Guard',
    disableSortBy: false,
    accessor: 'guard',
    Cell: ({ value }) => <IncidentGuardCell incident={value} />,
    width: 80,
  },
]
