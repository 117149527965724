import { AuthProvider } from '@/hooks/useAuth'
import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'
import { NotificationsProvider } from '@/hooks/useOnNotification'
import { SSRKeycloakProvider } from '@react-keycloak/ssr'
import NProgress from 'nextjs-progressbar'
import 'react-datepicker/dist/react-datepicker.css'
import { useStore } from 'react-redux'

import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'
import { AnimateSharedLayout } from 'framer-motion'
import 'raf/polyfill'
import { PersistGate } from 'redux-persist/integration/react'

import Head from '@/components/layouts/Head'
import { useApollo } from '@/config/apollo'
import keycloak from '@/config/keycloak'
import { Scripts } from '@/config/scripts'
import {
  CallContainer,
  CallFlaggedProvider as CallProvider,
} from '@/features/emergency-call'
import { useAppSelector } from '@/redux/hooks'
import { selectMe } from '@/redux/me/meSlice'
import { wrapper } from '@/redux/store'
import type { AppStore } from '@/redux/store'
import Fonts from '@/styles/fonts'
import theme from '@/styles/theme'
import { useIdentifyUser } from '@/utils/analytics'
import '@/utils/analytics/mixpanel'

import '../../global.css'

const MyApp = ({ Component, pageProps }) => {
  const { initOptions, keycloakConfig, onEvent, onTokens, persistor } =
    keycloak()
  const client = useApollo(pageProps)
  const store: AppStore = useStore()
  const isEmergencyCallEnabled = useFeatureFlag(FeatureFlag.emergencyCall)

  const me = useAppSelector(selectMe)
  useIdentifyUser(me)

  let origin
  if (typeof window !== 'undefined') {
    origin = `${window.location.origin}/`
  }

  const extendedInitOptions = {
    ...initOptions,
    redirectUri: origin,
  }

  return (
    <PersistGate persistor={store.__persistor} loading={null}>
      <SSRKeycloakProvider
        keycloakConfig={keycloakConfig}
        persistor={persistor}
        onEvent={onEvent}
        initOptions={extendedInitOptions}
        onTokens={onTokens}
      >
        <Head />
        <ApolloProvider client={client}>
          <ChakraProvider resetCSS theme={theme}>
            <AuthProvider>
              <NotificationsProvider>
                <CallProvider>
                  <NProgress
                    color='#2E6CDB'
                    height={4}
                    options={{ showSpinner: false, easing: 'ease', speed: 500 }}
                  />
                  <Fonts />
                  <Global
                    styles={css`
                      body {
                        background-color: #f0f5fd;
                      }
                    `}
                  />
                  <AnimateSharedLayout>
                    <Scripts />
                    <Component {...pageProps} />
                  </AnimateSharedLayout>
                  {isEmergencyCallEnabled && <CallContainer />}
                </CallProvider>
              </NotificationsProvider>
            </AuthProvider>
          </ChakraProvider>
        </ApolloProvider>
      </SSRKeycloakProvider>
    </PersistGate>
  )
}

export default wrapper.withRedux(MyApp)
