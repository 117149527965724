import { useSelector } from 'react-redux'

import { AccordionPanel, Box, Flex, Grid } from '@chakra-ui/react'

import { selectEditMode } from '@/redux/ui/uiSlice'
import { removeUnderscoreAndCapitalize } from '@/utils/formats'

import { IAccount, RoleExtended } from '../types/types.d'
import AccountEdit from './AccountEdit'

const AccountRow = ({ label, value }: { label: string; value: string }) => (
  <>
    <Box color='#999a9e' fontWeight='medium' letterSpacing='-0.53px'>
      {label}:
    </Box>
    <Box
      fontWeight='bold'
      letterSpacing='-0.53px'
      data-testid='AccountRow:value'
    >
      {value}
    </Box>
  </>
)

export const Account = ({
  index,
  account,
  role,
}: {
  index: number
  account: IAccount
  role: RoleExtended
}) => {
  const editMode = useSelector(selectEditMode)
  const {
    id: userId,
    firstName,
    lastName,
    email,
    facilities,
    deviceTypes,
  } = account
  const fullName = `${firstName} ${lastName}`
  const facilitiesDisplayName =
    (facilities?.map((f) => f.name).join(', ') ?? 'n/a') || 'n/a'
  const deviceTypesDisplayName =
    (deviceTypes?.map((d) => removeUnderscoreAndCapitalize(d)).join(', ') ??
      'n/a') ||
    'n/a'
  return (
    <AccordionPanel
      bgColor={index % 2 === 0 ? 'transparent' : 'white'}
      borderTop='1px solid #eaedf3'
      p='0'
    >
      <Flex px='4' py='6' justifyContent='space-between' alignItems='center'>
        <Grid gridTemplateColumns='auto 1fr' w='full' columnGap='6' rowGap='4'>
          <AccountRow label='Name' value={fullName} />
          <AccountRow label='Email' value={email} />
          {role === RoleExtended.TECHNICIAN && (
            <>
              <AccountRow label='Devices' value={deviceTypesDisplayName} />
              <AccountRow label='Facilities' value={facilitiesDisplayName} />
            </>
          )}
        </Grid>
        {editMode && (
          <AccountEdit role={role} userId={userId} fullName={fullName} />
        )}
      </Flex>
    </AccordionPanel>
  )
}
