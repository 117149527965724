import { useManagedAccounts } from '../hooks/useManagedAccounts'

import { Accordion, Box, Flex, Spinner } from '@chakra-ui/react'

import { Input } from '@/components/ui'

import { Account } from './Account'
import { AccountGroup } from './AccountGroup'

export default function ManagedAccounts() {
  const { groups, loading, filter, setFilter } = useManagedAccounts()

  return (
    <Box>
      <Box fontSize='24px' fontWeight='extrabold' letterSpacing='-0.8px' mb='4'>
        Managed Accounts
      </Box>
      <Input
        data-testid='settingsPage_managedAccounts_nameFilter'
        onChange={(event) => setFilter(event.target.value)}
        mb='6'
        placeholder='Filter by name'
      />
      {loading ? (
        <Flex justifyContent='center' mt='10'>
          <Spinner size='lg' />
        </Flex>
      ) : (
        <Accordion allowMultiple allowToggle>
          {groups.map(({ role, accounts }) => (
            <AccountGroup role={role} filter={filter} key={role}>
              {accounts.map((account, index: number) => (
                <Account
                  index={index}
                  account={account}
                  role={role}
                  key={account.id}
                />
              ))}
            </AccountGroup>
          ))}
        </Accordion>
      )}
    </Box>
  )
}
