import { useEffect, useState } from 'react'

import {
  useManagedAccountsQuery,
  useTechniciansQuery,
} from '@/graphql/generated/hooks'

import { IAccount, IAccountGroups, RoleExtended } from '../types/types.d'
import { filterAccounts, groupAccounts } from '../utils/helpers'

export const useManagedAccounts = () => {
  const { loading: usersLoading, data: usersData } = useManagedAccountsQuery({
    nextFetchPolicy: 'network-only',
  })
  const { loading: techniciansLoading, data: techniciansData } =
    useTechniciansQuery({
      nextFetchPolicy: 'network-only',
    })

  const [filter, setFilter] = useState('')
  const [groups, setGroups] = useState<IAccountGroups>([])

  useEffect(() => {
    const users = (usersData?.managedAccounts.edges.map((e) => e.node) ??
      []) as IAccount[]
    const technicians = (techniciansData?.technicians.edges.map((e) => ({
      ...e.node,
      roleName: RoleExtended.TECHNICIAN,
    })) ?? []) as IAccount[]

    const accounts = [...users, ...technicians]
    const accountsFiltered = filterAccounts(accounts, filter)
    const accountsGrouped = groupAccounts(accountsFiltered)

    setGroups(accountsGrouped)
  }, [usersData, techniciansData, filter])

  const loading = usersLoading || techniciansLoading

  return { groups, loading, filter, setFilter }
}
