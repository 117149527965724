import Script from 'next/script'

export const Scripts = () => {
  return (
    <Script
      async={true}
      type='text/javascript'
      src='https://cdn.whatfix.com/prod/0a6bf2ed-cef8-4a9e-8f59-4da735ac9343/1671737228012/initiator/initiator.nocache.js'
      integrity='sha384-hoKwytObtKEWXvRNUXulYqOYQd1nABWQ5AsnQlAaXWaVdUqiFFnR8IPNWlQaHV89'
      crossOrigin='anonymous'
    />
  )
}
