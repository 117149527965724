import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-Light.ttf) format('truetype');
        font-weight: 300;
        font-style: normal;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-LightItalic.ttf) format('truetype');
        font-weight: 300;
        font-style: italic;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-Regular.ttf) format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-RegularItalic.ttf) format('truetype');
        font-weight: 400;
        font-style: italic;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-Medium.ttf) format('truetype');
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-MediumItalic.ttf) format('truetype');
        font-weight: 500;
        font-style: italic;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-Semibold.ttf) format('truetype');
        font-weight: 600;
        font-style: normal;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-SemiboldItalic.ttf) format('truetype');
        font-weight: 600;
        font-style: italic;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-Bold.ttf) format('truetype');
        font-weight: 700;
        font-style: normal;
      }
      @font-face {
        font-family: 'Euclid Square';
        src: url(/fonts/EuclidSquare-BoldItalic.ttf) format('truetype');
        font-weight: 700;
        font-style: italic;
      }
    `}
  />
)

export default Fonts
