import { default as RDatePicker } from 'react-datepicker'

import { InputProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

const StyledDatePicker = styled(RDatePicker)`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  color: #000;
  border: 2px solid
    ${(p) => (p.valid ? '#2BD2B4' : p.isInvalid ? '#D01030' : '#9FB8E5')};
  min-height: 48px;
  border-radius: 6px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;

  ::placeholder {
    font-size: 14px;
    color: #2d2e41;
    opacity: 30%;
    font-style: italic;
    font-weight: 500;
  }
`

export interface InputIProps extends InputProps {
  name: string
  placeholder?: string
  [key: string]: unknown
}

export default function DatePicker({
  placeholder,
  inputRef,
  ...rest
}: InputIProps) {
  return (
    <StyledDatePicker
      {...rest}
      minDate={new Date()}
      placeholder={placeholder}
      innerRef={inputRef}
      showTimeSelect
      timeFormat='HH:mm'
      timeIntervals={15}
      timeCaption='Time'
      dateFormat='HH:mm:ss   M/dd/yyyy'
    />
  )
}
