import { useEffect } from 'react'

import useMyRole from '@/hooks/useMyRole'
import useToasts from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle, HiOutlineTrash } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import * as EmailValidator from 'email-validator'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import { RoleExtended } from '@/features/managed-accounts'
import {
  ManagedAccountsDocument,
  useFacilityNamesQuery,
  useUserQuery,
} from '@/graphql/generated/hooks'
import { useUpdateUserMutation } from '@/graphql/generated/hooks'
import { getFacilityOptions } from '@/utils/forms/selectOptions'

import { RoleIcon, getRoleDisplayName } from './UserRoles'

function FormSkeleton() {
  return (
    <Box px={6} py={2} mb={3}>
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' mb='20px' />
      <Skeleton h='15px' maxW='120px' mb='9px' />
      <Skeleton h='45px' />
    </Box>
  )
}

const IconWrapperStyled = styled(Box)`
  .icon {
    width: 30px;
  }
`
interface FormInputs {
  firstName: string
  lastName: string
  email: string
  designatedFacility: { value: string; label: string }
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  onRemoveOpen: () => void
  userId: string
  role: RoleExtended
}

export default function EditUserModal({
  isOpen,
  onClose,
  onRemoveOpen,
  userId,
  role,
}: IProps) {
  const { isImplementerOrPM } = useMyRole()
  const { handleSubmit, register, control, errors, setValue } =
    useForm<FormInputs>({
      mode: 'onChange',
    })
  const { data: facilitiesData, loading: facilietiesLoading } =
    useFacilityNamesQuery({
      fetchPolicy: 'network-only',
    })
  const { data: userData, loading: userLoading } = useUserQuery({
    variables: {
      id: userId,
    },
    fetchPolicy: 'network-only',
  })
  const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation()

  const facilityOptions = getFacilityOptions(facilitiesData)

  const { showSuccess, showError } = useToasts()

  const onSubmit: SubmitHandler<FormInputs> = async (values) => {
    const data = {
      id: userId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roleName: role === RoleExtended.EMPLOYEE ? '' : role,
      designatedFacilityId: values.designatedFacility.value,
    }

    try {
      await updateUser({
        variables: {
          input: data,
        },
        refetchQueries: [
          {
            query: ManagedAccountsDocument,
          },
        ],
      })
      onClose()
      showSuccess(`${getRoleDisplayName(role)} edits saved.`)
    } catch {
      showError()
    }
  }

  useEffect(() => {
    if (userData && facilitiesData) {
      setValue('firstName', userData?.user?.firstName, {
        shouldValidate: true,
      })
      setValue('lastName', userData?.user?.lastName, { shouldValidate: true })
      setValue('email', userData?.user?.email, { shouldValidate: true })
      setValue(
        'designatedFacility',
        facilityOptions.find(
          (facility) => facility.value === userData?.user?.designatedFacilityId
        ),
        {
          shouldValidate: true,
        }
      )
    }
  }, [userLoading, facilietiesLoading])

  return (
    <Modal
      size='xl'
      isOpen={isOpen}
      onClose={onClose}
      motionPreset='slideInBottom'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box d='flex' alignItems='center'>
            <IconWrapperStyled>
              <RoleIcon role={role} />
            </IconWrapperStyled>
            <Box ml={3}>
              <Box
                fontSize='26px'
                color='#353849'
                letterSpacing='-0.4px'
                fontWeight='extrabold'
                lineHeight='1'
              >
                {`Edit ${getRoleDisplayName(role)}`}
              </Box>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton color='#000' opacity={0.4} />
        <ModalBody p={0}>
          {userData && !userLoading ? (
            <Box px={6} py={2}>
              <form data-testid='EditUserModal:form'>
                <Box mb={3}>
                  <FormInputControl
                    id='firstName'
                    label={`${getRoleDisplayName(role)} First Name`}
                    placeholder={`Enter ${getRoleDisplayName(role)} First Name`}
                    errorMessage={errors.firstName && errors.firstName.message}
                    isInvalid={!!errors.firstName}
                    inputRef={register({
                      required: 'Please add first name',
                    })}
                    data-testid='EditUserModal:firstName'
                  />
                </Box>
                <Box mb={3}>
                  <FormInputControl
                    id='lastName'
                    label={`${getRoleDisplayName(role)} Last Name`}
                    placeholder={`Enter ${getRoleDisplayName(role)} Last Name`}
                    errorMessage={errors.lastName && errors.lastName.message}
                    isInvalid={!!errors.lastName}
                    inputRef={register({
                      required: 'Please add last name',
                    })}
                    data-testid='EditUserModal:lastName'
                  />
                </Box>

                <Box mb={3}>
                  <FormInputControl
                    id='email'
                    type='email'
                    label={`${getRoleDisplayName(role)} Email`}
                    placeholder={`Enter ${getRoleDisplayName(role)} Email`}
                    errorMessage={errors.email && errors.email.message}
                    isInvalid={!!errors.email}
                    inputRef={register({
                      required: 'You must specify an email',
                      validate: {
                        isEmail: (v) =>
                          EmailValidator.validate(v) || 'Invalid email address',
                      },
                    })}
                    data-testid='EditUserModal:email'
                  />
                </Box>
                <Box mb={3}>
                  <FormInputSelectControl
                    id='designatedFacility'
                    label={`${getRoleDisplayName(role)} Facility`}
                    placeholder={`Enter ${getRoleDisplayName(role)} Facility`}
                    errorMessage={
                      !!errors?.designatedFacility &&
                      'You must select a facility'
                    }
                    isInvalid={!!errors.designatedFacility}
                    control={control}
                    options={facilityOptions}
                    rules={{ required: true }}
                  />
                </Box>
              </form>
            </Box>
          ) : (
            <FormSkeleton />
          )}
        </ModalBody>
        <ModalFooter py={4}>
          <Button
            variant='secondary'
            onClick={onClose}
            mr={3}
            data-testid='EditUserModal:cancel'
          >
            Cancel
          </Button>
          <Button
            variant='danger'
            isDisabled={userLoading || !userData}
            onClick={() => {
              onClose()
              onRemoveOpen()
            }}
            leftIcon={<HiOutlineTrash size='24px' />}
            loadingText={`Removing ${getRoleDisplayName(role)}`}
            mr={3}
            data-testid='EditUserModal:remove'
          >
            {`Remove ${getRoleDisplayName(role)}`}
          </Button>
          <Button
            isLoading={updateUserLoading}
            isDisabled={userLoading || !userData || !isImplementerOrPM}
            onClick={handleSubmit(onSubmit)}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Saving changes...'
            data-testid='EditUserModal:submit'
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
