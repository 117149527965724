import { Libraries, useGoogleMapsScript } from 'use-google-maps-script'

import { Tooltip } from '@chakra-ui/react'
import '@reach/combobox/styles.css'

import {
  AddressSearchInput,
  AddressSearchInputIProps,
} from './AddressSearchInput'

const libraries: Libraries = ['places']

interface AddressSearchIProps extends AddressSearchInputIProps {
  showTooltip?: boolean
}

export const AddressSearch = ({
  onSelectAddress,
  defaultValue,
  isSubmitting,
  disabled = false,
  showTooltip = true,
}: AddressSearchIProps) => {
  const { isLoaded, loadError } = useGoogleMapsScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY ?? '',
    libraries,
  })
  if (!isLoaded || loadError) {
    return null
  }
  return (
    <Tooltip
      hasArrow
      shouldWrapChildren
      label='Contact HiveWatch to change'
      placement='top'
      isDisabled={!disabled || !showTooltip}
    >
      <AddressSearchInput
        onSelectAddress={onSelectAddress}
        defaultValue={defaultValue}
        isSubmitting={isSubmitting}
        disabled={disabled}
      />
    </Tooltip>
  )
}
