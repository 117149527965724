export enum Role {
  PROGRAM_MANAGER = 'PROGRAM_MANAGER',
  GUARD = 'GUARD',
  OPERATOR = 'OPERATOR',
  READ_ONLY_USER = 'READ_ONLY_USER',
  IMPLEMENTER = 'IMPLEMENTER',
}

// ExtendedRole provide useful extension of standard role system to support
// users outside of the organization and users without specific role within
// organization. Technicians don't have HiveWatch accounts and exist outside
// of the organization, whereas employees are organization members which are
// NOT program managers, operators or guards.
export enum RoleExtended {
  PROGRAM_MANAGER = 'PROGRAM_MANAGER',
  OPERATOR = 'OPERATOR',
  GUARD = 'GUARD',
  EMPLOYEE = 'EMPLOYEE',
  TECHNICIAN = 'TECHNICIAN',
  IMPLEMENTER = 'IMPLEMENTER',
}

export const DEFAULT_ROLE = 'default-roles-hivewatchdev.com'

export interface IAccount {
  id: string
  email: string
  firstName: string
  lastName: string
  roleName: RoleExtended | '' | 'default-roles-hivewatchdev.com'
  facilities?: Pick<Facility, 'name'>[]
  deviceTypes?: DeviceType[]
}

export interface IAccountGroup {
  role: RoleExtended
  accounts: IAccount[]
}

export interface IAccountGroups extends Array<IAccountGroup> {}
