// List of allowed feature flags
export enum FeatureFlag {
  dashboardHome = 'dashboardHome',
  dashboardOITableFilters = 'dashboardOITableFilters',
  dashboardOITableIncidentTypeColumn = 'dashboardOITableIncidentTypeColumn',
  cannedDashboardBarCartData = 'cannedDashboardBarCartData',
  legacyDashboard = 'legacyDashboard',
  orderRepair = 'orderRepair',
  deviceHealthSettings = 'deviceHealthSettings',
  cannedVideo = 'cannedVideo',
  salesDatabaseReset = 'salesDatabaseReset',
  emergencyCall = 'emergencyCall',
  camioCameraStream = 'camioCameraStream',
  orangePeelCameraStream = 'orangePeelCameraStream',
}

type FlagEntries = Array<[FeatureFlag, 'true' | 'false']>

export type Flags = {
  [key in FeatureFlag]?: boolean
}

const parseFlagEntries = (flagEntries: FlagEntries): Flags => {
  const flags = {}
  for (const [name, value] of flagEntries) {
    switch (value) {
      case 'true':
        flags[name] = true
        break
      case 'false':
        flags[name] = false
        break
      default:
        throw new Error(`incorrect value: ${value} for flag ${name}`)
    }
  }
  return flags
}

const flagEntries = process.env.NEXT_PUBLIC_FEATURE_FLAGS
  ? process.env.NEXT_PUBLIC_FEATURE_FLAGS?.split(',').map((flagString) =>
      flagString.split(':')
    )
  : []

const FEATURE_FLAGS = parseFlagEntries(flagEntries as FlagEntries)

export const useFeatureFlag = (flag: FeatureFlag): boolean => {
  return FEATURE_FLAGS?.[flag]
}

export const useFeatureFlags = (): Flags => {
  return FEATURE_FLAGS
}

type ProviderType = ({ children }: { children: React.ReactNode }) => JSX.Element

export const flagProvider = (provider: ProviderType, flag: FeatureFlag) => {
  const isEnabled = useFeatureFlag(flag)
  return isEnabled ? provider : ({ children }) => <>{children}</>
}
