import { useSelector } from 'react-redux'

import { Role } from '@/features/managed-accounts'
import { selectRole } from '@/redux/me/meSlice'

const useReadOnly = (): { isReadOnly: boolean } => {
  const role = useSelector(selectRole)
  return { isReadOnly: role === Role.READ_ONLY_USER }
}

export default useReadOnly
