import { Control, Controller } from 'react-hook-form'
import { Validate } from 'react-hook-form/dist/types'

import {
  FormControl as ChakraFormControl,
  FormControlOptions,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import InputSelect from '../InputSelect'

export const StyledFormInputSelectControl = styled(ChakraFormControl)`
  .chakra-form__label {
    opacity: 0.5;
    color: #2d2e41;
    font-size: 16px;
    letter-spacing: -0.8px;
  }
`

export interface FormInputSelectControlIProps extends FormControlOptions {
  dataTestId?: string
  label?: string
  id?: string
  valid?: boolean
  errorMessage?: string
  width?: string
  isInvalid?: boolean
  placeholder?: string
  options?:
    | {
        value: string | unknown
        label: string | unknown
      }[]
    | {
        label: string
        options: {
          value: string
          label: string
        }[]
      }[]
  control?: Control
  rules?: {
    required?: boolean
    validate?: Validate
  }
  [key: string]: unknown
}

export default function FormInputSelectControl({
  id,
  label,
  isInvalid = false,
  valid = false,
  errorMessage,
  options,
  control,
  width = '100%',
  placeholder,
  rules,
  dataTestId,
  ...rest
}: FormInputSelectControlIProps) {
  return (
    <StyledFormInputSelectControl
      data-testid={dataTestId}
      width={width}
      isInvalid={isInvalid}
      id={id}
    >
      <FormLabel htmlFor={id} mr='0'>
        {label}
      </FormLabel>
      <Controller
        data-testid={dataTestId}
        as={InputSelect}
        options={options}
        name={id}
        control={control}
        placeholder={placeholder}
        isInvalid={isInvalid}
        valid={valid}
        rules={rules}
        {...rest}
      />
      <FormErrorMessage data-testid='form-control-select-error'>
        {errorMessage}
      </FormErrorMessage>
    </StyledFormInputSelectControl>
  )
}
