import { useSelector } from 'react-redux'

import { Role } from '@/features/managed-accounts'
import { selectRole } from '@/redux/me/meSlice'

interface IRole {
  role: Role
  isReadOnly: boolean
  isProgramManager: boolean
  isOperator: boolean
  isGuard: boolean
  isImplementer: boolean
  isImplementerOrPM: boolean
  isImplementerOrReadOnly: boolean
  isOperatorOrReadOnly: boolean
  isAdmin: boolean
}

const useMyRole = (): IRole => {
  const role = useSelector(selectRole)
  return {
    role,
    isReadOnly: role === Role.READ_ONLY_USER,
    isProgramManager: role === Role.PROGRAM_MANAGER,
    isOperator: role === Role.OPERATOR,
    isGuard: role === Role.GUARD,
    isImplementer: role === Role.IMPLEMENTER,
    isImplementerOrPM:
      role === Role.IMPLEMENTER || role === Role.PROGRAM_MANAGER,
    isImplementerOrReadOnly:
      role === Role.IMPLEMENTER || role === Role.READ_ONLY_USER,
    isOperatorOrReadOnly:
      role === Role.OPERATOR || role === Role.READ_ONLY_USER,
    isAdmin:
      role === Role.IMPLEMENTER ||
      role === Role.READ_ONLY_USER ||
      role === Role.PROGRAM_MANAGER,
  }
}

export default useMyRole
