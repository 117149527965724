import { useCall } from '../hooks/useCall'

import { Button, Tooltip, useDisclosure } from '@chakra-ui/react'

import { MixpanelData } from '@/features/incident-drawer'
import { IncidentQuery } from '@/graphql/generated/operations'

import ConfirmEmergencyCallDialog from './ConfirmEmergencyCallDialog'

interface EmergencyCallButtonIProps {
  incident: IncidentQuery['incident']
  mixpanelData?: MixpanelData
}

export const EmergencyCallButton = ({
  incident,
  mixpanelData,
  ...props
}: EmergencyCallButtonIProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isOpen: isCallOpen, openCall } = useCall()

  const bgColor = '#e3eefb'
  const hoverBgColor = '#1b253f'

  const handleCallClick = () => {
    openCall(
      {
        incidentId: incident?.id,
        incidentDisplayId: incident?.displayId,
        facilityName: incident?.facility?.name,
      },
      mixpanelData
    )
    onClose()
  }

  return (
    <>
      <Tooltip
        p='10px'
        bg={hoverBgColor}
        arrow={hoverBgColor}
        color='white'
        label='Call 911'
        mt='5px'
        hasArrow
        openDelay={250}
      >
        <Button
          bgColor={bgColor}
          border='none'
          ml='5px'
          fontSize='20px'
          width='82px'
          _hover={{ bgColor: isCallOpen ? 'auto' : hoverBgColor }}
          _focus={{ bgColor: isCallOpen ? 'auto' : bgColor }}
          _disabled={{
            opacity: 0.4,
            cursor: 'not-allowed',
            _hover: {
              bgColor: bgColor,
            },
          }}
          color='#E53E3E'
          onClick={onOpen}
          disabled={isCallOpen}
          {...props}
        >
          911
        </Button>
      </Tooltip>
      {isOpen && (
        <ConfirmEmergencyCallDialog
          isOpen={isOpen}
          onClose={onClose}
          handleOnClick={handleCallClick}
        />
      )}
    </>
  )
}
