import {
  FormControl as ChakraFormControl,
  FormControlOptions,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import DatePicker from '../DatePicker'

export const StyledFormInputControl = styled(ChakraFormControl)`
  .chakra-form__label {
    opacity: 0.5;
    color: #2d2e41;
    font-size: 16px;
  }
`

export interface FormDatePickerControlIProps extends FormControlOptions {
  label?: string
  id?: string
  type?: string
  valid?: boolean
  errorMessage?: string
  width?: string
  inputRef?: unknown
  leftIcon?: React.ElementType
  isInvalid?: boolean
  placeholder?: string
  rules?: {
    required?: boolean
  }
  [key: string]: unknown
}

export default function FormControl({
  id,
  label,
  isInvalid = false,
  errorMessage,
  width = '100%',
  placeholder,
  rules,
  ...rest
}: FormDatePickerControlIProps) {
  return (
    <StyledFormInputControl width={width} isInvalid={isInvalid} id={id}>
      <FormLabel htmlFor={id} mr='0'>
        {label}
      </FormLabel>
      <DatePicker name={id} placeholder={placeholder} rules={rules} {...rest} />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </StyledFormInputControl>
  )
}
