import { ChangeEvent } from 'react'

import { BiSearch } from 'react-icons/bi'

import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'

interface IProps {
  placeholder?: string
  value?: string
  showIcon?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  dataTestId?: string
}

export default function SearchInput({
  placeholder,
  value,
  showIcon = true,
  onChange,
  dataTestId,
}: IProps) {
  return (
    <InputGroup>
      {showIcon && (
        <InputLeftElement
          pointerEvents='none'
          fontSize='1.2em'
          children={<BiSearch />}
        />
      )}
      <Input
        data-testid={dataTestId}
        variant='outline'
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='off'
        borderWidth='1px'
        borderColor='#D5DCE4'
        bgColor='white'
        fontWeight='medium'
        _placeholder={{
          fontSize: '14px',
        }}
        color='#353849'
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </InputGroup>
  )
}
