import { useEffect, useRef } from 'react'

import { useLocalState } from '@/hooks/useLocalState'
import ReactMapGL, { FlyToInterpolator } from 'react-map-gl'

import {
  ViewportState,
  calculateBoundingViewport,
  defaultViewport,
  isDefaultViewport,
} from '.'

export interface MapBoxIProps {
  facilitiesPoints?: [number, number][]
  setDataBounds?: (bounds: string) => void
  height?: number
  width?: number
  children?: React.ReactNode
}

const transitionInterpolator = new FlyToInterpolator()
const MAP_STYLE = 'mapbox://styles/mapbox/satellite-v9'

export default function MapBox({
  facilitiesPoints,
  height = 500,
  width = 800,
  setDataBounds,
  children,
}: MapBoxIProps) {
  const mapRef = useRef(null)
  const [viewport, setViewport] = useLocalState<ViewportState | null>(
    'viewport',
    defaultViewport
  )

  useEffect(() => {
    if (facilitiesPoints && isDefaultViewport(viewport)) {
      setViewport(calculateBoundingViewport(facilitiesPoints, width, height))
    }
  }, [facilitiesPoints])

  const onViewportChange = (viewport: ViewportState) => {
    if (!isDefaultViewport(viewport) && facilitiesPoints) {
      setViewport(viewport)
    }
  }

  const updateDataBounds = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getMap().getBounds()
      setDataBounds(JSON.stringify(bounds.toArray()))
    }
  }

  return (
    <ReactMapGL
      {...viewport}
      width={width}
      height={height}
      transitionInterpolator={transitionInterpolator}
      transitionDuration={10}
      mapStyle={MAP_STYLE}
      onViewportChange={onViewportChange}
      ref={mapRef}
      onLoad={updateDataBounds}
      onInteractionStateChange={(extra) => {
        if (!extra.isDragging) {
          updateDataBounds()
        }
      }}
      mapboxApiAccessToken={process.env.NEXT_PUBLIC_MAPBOX_API_TOKEN}
    >
      {children}
    </ReactMapGL>
  )
}
