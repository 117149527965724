export default {
  baseStyle: {
    fontWeight: 'bold',
    _focus: {
      boxShadow: '0 0 0 3px rgba(44,133,244,0.70)',
    },
  },
  sizes: {
    md: {
      h: '46px',
      fontSize: '16px',
      px: '32px',
    },
  },
  variants: {
    primary: {
      bgGradient: 'linear(to-r, brand.100, brand.200)',
      color: '#fff',
      boxShadow: '0 8px 16px 0 rgba(44,133,244,0.51)',
      _hover: {
        bgGradient: 'linear(to-r, brand.100, brand.200)',
        _disabled: {
          bgGradient: 'linear(to-r, brand.100, brand.200)',
        },
      },
    },
    success: {
      bgGradient: 'linear(to-r, #13B0B0, #46EF9F)',
      color: '#fff',
      boxShadow: '0 8px 16px 0 rgba(19,176,176,0.51)',
      _hover: {
        bgGradient: 'linear(to-r, #13B0B0, #46EF9F)',
        _disabled: {
          bgGradient: 'linear(to-r, #13B0B0, #46EF9F)',
        },
      },
      _focus: {
        boxShadow: '0 0 0 3px rgba(19,176,176,0.5)',
      },
    },
    secondary: {
      bg: 'brand.300',
      color: '#3279C7',
      _hover: {
        bg: 'brand.300',
        _disabled: {
          bg: 'brand.300',
        },
      },
    },
    real: {
      bgGradient: 'linear(to-r, #214c81, #374C9D)',
      color: '#fff',
      boxShadow: '0 8px 16px 0 rgba(44,133,244,0.51)',
      _hover: {
        bgGradient: 'linear(to-r, #214c81, #374C9D)',
        _disabled: {
          bgGradient: 'linear(to-r, #214c81, #374C9D)',
        },
      },
    },
    false: {
      bgGradient: 'linear(to-r, #890D20, #890D20)',
      color: '#fff',
      boxShadow: '0 8px 16px 0 rgba(189,25,25,0.51)',
      _hover: {
        bgGradient: 'linear(to-r, #890D20, #890D20)',
        _disabled: {
          bgGradient: 'linear(to-r, #890D20, #890D20)',
        },
      },
      _focus: {
        boxShadow: '0 0 0 3px rgba(189,25,25,0.5)',
      },
    },
    danger: {
      bg: '#D01030',
      color: '#fff',
      boxShadow: '0 8px 16px 0 rgba(189,25,25,0.51)',
      _hover: {
        bgGradient: '#D01030',
        _disabled: {
          bg: '#D01030',
          bgGradient: '#D01030',
        },
      },
      _focus: {
        boxShadow: '0 0 0 3px rgba(189,25,25,0.5)',
      },
    },
    tertiary: {
      borderWidth: '2px',
      borderColor: 'brand.900',
      color: 'brand.900',
    },
    link: {
      fontWeight: 500,
      color: 'brand.100',
      borderColor: 'brand.100',
      textDecoration: 'underline',
      textDecorationStyle: 'solid',
      textDecorationColor: 'brand.900',
      textDecorationThickness: '2px',
      _hover: {
        textDecoration: 'underline',
        textDecorationStyle: 'solid',
        textDecorationColor: 'brand.900',
        textDecorationThickness: '2px',
        _disabled: {
          textDecoration: 'underline',
          textDecorationStyle: 'solid',
          textDecorationColor: 'brand.900',
          textDecorationThickness: '2px',
        },
      },
    },
    outline: {
      fontWeight: 500,
      bg: '#fff',
      color: '#7C7C7C',
      borderColor: '#D9D9D9',
      paddingX: '8px',
      _hover: {
        bg: '#F0F0F0',
        borderColor: '#F0F0F0',
        color: '#1F2023',
      },
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        bg: '#F0F0F0',
      },
    },
    base: {
      fontWeight: 500,
      bg: '#F1F2F4',
      borderColor: '#F1F2F4',
      color: '#5C6C7A',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: '#F0F0F0',
        borderColor: '#F0F0F0',
        color: '#5C6C7A',
      },
    },
    affirm: {
      fontWeight: 500,
      bg: '#43BF9B',
      borderColor: '#43BF9B',
      color: '#FFFFFF',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        bg: '#3ca88f',
        borderColor: '#3ca88f',
        boxShadow:
          '0px 0px 1px rgba(9, 30, 66, 0.31), 0px 1px 1px rgba(9, 30, 66, 0.25)',
      },
      _hover: {
        bg: '#40b596',
        borderColor: '#40b596',
        boxShadow:
          '0px 0px 1px rgba(9, 30, 66, 0.31), 0px 8px 12px rgba(9, 30, 66, 0.15)',
        _disabled: {
          bg: '#f8f8f9',
          borderColor: '#f8f8f9',
          opacity: 1,
          color: '#8993A5',
          boxShadow: 'none',
        },
      },
      _disabled: {
        bg: '#f8f8f9',
        borderColor: '#f8f8f9',
        opacity: 1,
        color: '#8993A5',
      },
    },
    ghost: {
      color: '#5C6C7A',
      _hover: {
        bg: '#F0F0F0',
        borderColor: '#F0F0F0',
        color: '#5C6C7A',
      },
    },
    reset: {
      color: '#172B4D',
      bg: 'rgba(9, 30, 66, 0.14)',
      fontWeight: 'medium',
    },
  },
}
