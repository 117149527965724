import React from 'react'

const ProgramManager = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 48 48'
    className={className}
  >
    <defs>
      <style>
        {`.cls-2{fill:none;stroke:${strokeColor};strokeLinecap:round;strokeLinejoin:round;strokeWidth:3px}`}
      </style>
    </defs>
    <g id='guards-II_48px' data-name='guards-II 48px'>
      <path
        id='Path-47'
        style={{
          fill: 'none',
        }}
        d='M0 0h48v48H0V0z'
      />
      <g id='icon'>
        <path
          id='Path-48'
          className='cls-2'
          d='M44 37.76 24 45 4 37.76V3h40v34.76z'
        />
        <path id='Path-49' className='cls-2' d='m44 29-20 8-20-8' />
        <path
          id='Path-50'
          style={{
            fill: '#868686',
            fillRule: 'evenodd',
            stroke: strokeColor,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
          }}
          d='M24 21.18 20.29 24l1.42-4.57L18 16.77h4.58L24 12l1.42 4.77H30l-3.71 2.66L27.71 24 24 21.18z'
        />
      </g>
    </g>
  </svg>
)

export default ProgramManager
