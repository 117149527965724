import React from 'react'

const Employee = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h32v32H0z' />
      <path
        d='M12.889 7.667H5.11A3.111 3.111 0 0 0 2 10.777v14a3.111 3.111 0 0 0 3.111 3.112H26.89A3.111 3.111 0 0 0 30 24.778v-14a3.111 3.111 0 0 0-3.111-3.111H19.11'
        stroke={strokeColor}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.889 7.667V6.11a3.111 3.111 0 0 1 6.222 0v1.556M12.889 7.667a3.111 3.111 0 0 0 6.222 0M11.333 20.111a3.111 3.111 0 1 0 0-6.222 3.111 3.111 0 0 0 0 6.222ZM11.333 20.111a4.673 4.673 0 0 1 4.403 3.111'
        stroke={strokeColor}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.333 20.111a4.668 4.668 0 0 0-4.402 3.111'
        stroke={strokeColor}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      >
        <path d='M20.667 14.445h4.667M20.667 18.556h4M20.667 22.667h3.11' />
      </g>
    </g>
  </svg>
)

export default Employee
