import React from 'react'

const Technician = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 48 48'
    className={className}
  >
    <defs>
      <style>
        {`.cls-2{fill:none;stroke:${strokeColor};strokeLinecap:round;strokeLinejoin:round;strokeWidth:3px}`}
      </style>
    </defs>
    <g id='orderrepair_48px' data-name='orderrepair 48px'>
      <path
        id='Path-106'
        style={{
          fill: 'none',
        }}
        d='M0 0h48v48H0V0z'
      />
      <path
        id='Path-107'
        className='cls-2'
        d='M23.4 20.82 12.24 9.8l-.7-3.69L4.42 3 3 4.41l2.98 7.2 3.77.72 11.12 10.99'
      />
      <path
        id='Path-108'
        className='cls-2'
        d='m44.43 35.93-9.67-9.55a5.38 5.38 0 0 0-3-1.48l-3.23-4.61a1.94 1.94 0 0 0-2.68-.57 2 2 0 0 0-.32.26l-4.81 4.75a1.92 1.92 0 0 0-.57 1.57 1.89 1.89 0 0 0 .85 1.41l4.61 3.16a5.22 5.22 0 0 0 1.5 3l9.67 9.54a5.41 5.41 0 0 0 7.59 0 5.26 5.26 0 0 0 .09-7.41h-.05Z'
      />
      <path
        id='Path-109'
        className='cls-2'
        d='M14.49 27.86a2.74 2.74 0 0 1-2.32.77c-.34 0-.68-.06-1-.07A8.09 8.09 0 0 0 5 31.41 8.18 8.18 0 0 0 3.1 38l5.5-3.5a.92.92 0 0 1 1.07.07l2 1.68a1.05 1.05 0 0 1 .3.45l.63 2.24a.89.89 0 0 1-.42 1l-5.86 3.38a8 8 0 0 0 5.37 1.6 8.12 8.12 0 0 0 5.78-3 8.28 8.28 0 0 0 1.78-6.3 2.8 2.8 0 0 1 .75-2.34'
      />
      <path
        id='Path-110'
        className='cls-2'
        d='M33.5 20.14a2.74 2.74 0 0 1 2.32-.77c.33 0 .67.06 1 .07A8.11 8.11 0 0 0 43 16.59 8.25 8.25 0 0 0 44.88 10l-5.5 3.5a.92.92 0 0 1-1.07-.07l-2-1.68a1 1 0 0 1-.29-.45L35.4 9a.89.89 0 0 1 .42-1l5.86-3.38A8.1 8.1 0 0 0 30.53 6a8.28 8.28 0 0 0-1.78 6.3 2.77 2.77 0 0 1-.75 2.34'
      />
    </g>
  </svg>
)

export default Technician
