import React from 'react'

import { useCall } from '../hooks/useCall'
import { useSelector } from 'react-redux'

import { Collapse, chakra, shouldForwardProp } from '@chakra-ui/react'
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion'

import { selectSideNavOpen } from '@/redux/ui/uiSlice'
import {
  CALL_CONTAINER_Z_INDEX,
  SIDE_BAR_WIDTH_CLOSED,
  SIDE_BAR_WIDTH_OPEN,
} from '@/styles/theme'

import { CallExpanded } from './CallExpanded'
import { CallMinimized } from './CallMinimized'

const MotionBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const CallContainer = () => {
  const sideNavOpen = useSelector(selectSideNavOpen)
  const { isOpen, isExpanded, callStatus } = useCall()

  return (
    <AnimatePresence>
      {isOpen && (
        <MotionBox
          as={motion.div}
          key='CallContainer'
          pos='fixed'
          bottom={0}
          left={!sideNavOpen ? SIDE_BAR_WIDTH_CLOSED : SIDE_BAR_WIDTH_OPEN}
          ml='20px'
          zIndex={CALL_CONTAINER_Z_INDEX}
          w='450px'
          bg='white'
          p='20px'
          shadow='0px 2px 5px 6px rgba(0, 0, 0, 0.1)'
          borderTopLeftRadius='16px'
          borderTopRightRadius='16px'
          borderBottomLeftRadius={isExpanded ? '16px' : '0px'}
          borderBottomRightRadius={isExpanded ? '16px' : '0px'}
          initial={{ x: '-100%', opacity: 0 }}
          exit={{ x: '-100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ ease: 'easeInOut', duration: '0.3' }}
        >
          <Collapse
            startingHeight={'70px'}
            endingHeight={'680px'}
            in={isExpanded}
          >
            {isExpanded ? (
              <CallExpanded callStatus={callStatus} />
            ) : (
              <CallMinimized />
            )}
          </Collapse>
        </MotionBox>
      )}
    </AnimatePresence>
  )
}
