import { BiLinkAlt } from 'react-icons/bi'
import { HiCheckCircle } from 'react-icons/hi'

import {
  Box,
  Divider,
  HStack,
  Icon,
  Skeleton,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react'

interface IProps {
  loading: boolean
  label: string
  hideDivider?: boolean
  copyMessage?: string
  hasCopiedMessage?: string
  link: string
}

export default function DrawerTextClipboard({
  loading,
  label,
  link,
  hideDivider = false,
  copyMessage = 'Copy',
  hasCopiedMessage = 'Copied!',
}: IProps) {
  const { hasCopied, onCopy } = useClipboard(link, 5000)
  if (!label && !loading) {
    return null
  }

  return (
    <HStack spacing='14px'>
      {loading ? (
        <Skeleton w='40px' h='16px' />
      ) : (
        <Tooltip
          closeOnClick={false}
          label={hasCopied ? hasCopiedMessage : copyMessage}
          placement='bottom'
          rounded='5px'
          p='2'
          bgColor='#fff'
          color='#1a202c'
          fontSize='16px'
          fontWeight='semibold'
          letterSpacing='-0.65px'
          boxShadow='md'
          shouldWrapChildren
        >
          <HStack
            spacing='1'
            alignItems='center'
            cursor='pointer'
            onClick={onCopy}
          >
            <Box
              letterSpacing='-0.53px'
              fontSize='16px'
              fontWeight='semibold'
              _hover={{ textDecoration: 'underline' }}
              data-testid='incidentDrawer_header_incidentId'
            >
              {label}
            </Box>
            {hasCopied ? (
              <Icon
                aria-label='Copied!'
                boxSize='4'
                as={HiCheckCircle}
                color='success.100'
              />
            ) : (
              <Icon
                aria-label='Copy to clipboard'
                boxSize='4'
                as={BiLinkAlt}
                color='#1a202c'
              />
            )}
          </HStack>
        </Tooltip>
      )}
      {!hideDivider && (
        <Divider
          h='20px'
          borderColor='#D7D4D4'
          orientation='vertical'
          borderLeftWidth='2px'
        />
      )}
    </HStack>
  )
}
