import React from 'react'

const SvgComponent = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    width='32'
    height='33'
    viewBox='0 0 32 33'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g transform='translate(0 .5)' fill='none' fillRule='evenodd'>
      <path d='M0 0h32v32H0z' />
      <rect
        stroke={strokeColor}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
        x={4}
        y={15.875}
        width={6}
        height={9}
        rx={2.667}
      />
      <rect
        stroke={strokeColor}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
        x={22}
        y={15.875}
        width={6}
        height={9}
        rx={2.667}
      />
      <path
        d='M4 18.875v-4.5c0-6.627 5.373-12 12-12s12 5.373 12 12v4.5M25 24.875c0 2.485-3.03 4.5-8 4.5'
        stroke={strokeColor}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
)

export default SvgComponent
