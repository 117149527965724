import { Box, BoxProps } from '@chakra-ui/react'

export default function Card(props: BoxProps) {
  return (
    <Box
      bg='white'
      py='8'
      px='10'
      shadow='0px 2px 6px 2px rgba(0, 0, 0, 0.1)'
      rounded='5px'
      {...props}
    />
  )
}
