import useToasts from '@/hooks/useToasts'
import { useSelector } from 'react-redux'

import { useDisclosure } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ConfirmRemovalDialog } from '@/components/ui/ConfirmRemovalDialog'
import EditRemoveButton from '@/components/ui/EditRemoveButton'
import {
  ManagedAccountsDocument,
  TechniciansDocument,
  useDeleteTechnicianMutation,
  useDeleteUserMutation,
} from '@/graphql/generated/hooks'
import { selectRole } from '@/redux/me/meSlice'

import { RoleExtended } from '../types/types.d'
import { hasCRUDPermission } from '../utils/helpers'
import EditTechnicianModal from './EditTechnicianModal'
import EditUserModal from './EditUserModal'
import { RoleIcon, getRoleDisplayName } from './UserRoles'

const IconWrapperStyled = styled(Box)`
  .icon {
    width: 30px;
    margin-right: 10px;
  }
`

const AccountEdit = ({
  role,
  userId,
  fullName,
}: {
  role: RoleExtended
  userId: string
  fullName: string
}) => {
  const {
    isOpen: isEditAccountOpen,
    onOpen: onEditAccountOpen,
    onClose: onEditAccountClose,
  } = useDisclosure()
  const {
    isOpen: isRemoveAccountOpen,
    onOpen: onRemoveAccountOpen,
    onClose: onRemoveAccountClose,
  } = useDisclosure()

  const { showSuccess, showError } = useToasts()
  const userRole = useSelector(selectRole)
  const hasPermission = hasCRUDPermission(role, userRole)
  const [deleteUser, { loading: deleteUserLoading }] = useDeleteUserMutation()
  const [deleteTechnician, { loading: deleteTechnicianLoading }] =
    useDeleteTechnicianMutation()
  const deleteAccountLoading =
    role === RoleExtended.TECHNICIAN
      ? deleteTechnicianLoading
      : deleteUserLoading

  const onAccountRemove = async () => {
    try {
      if (role === RoleExtended.TECHNICIAN) {
        await deleteTechnician({
          variables: {
            input: {
              id: userId,
            },
          },
          refetchQueries: [
            {
              query: TechniciansDocument,
            },
          ],
        })
      } else {
        await deleteUser({
          variables: {
            input: {
              id: userId,
            },
          },
          refetchQueries: [
            {
              query: ManagedAccountsDocument,
            },
          ],
        })
      }
      showSuccess(`${getRoleDisplayName(role)} removed.`)
      onRemoveAccountClose()
    } catch {
      showError()
    }
  }

  return (
    <Box height={90}>
      {hasPermission && (
        <EditRemoveButton
          handleOnDelete={onRemoveAccountOpen}
          handleOnEdit={onEditAccountOpen}
        />
      )}
      {isEditAccountOpen &&
        (role !== RoleExtended.TECHNICIAN ? (
          <EditUserModal
            isOpen={isEditAccountOpen}
            onClose={onEditAccountClose}
            onRemoveOpen={onRemoveAccountOpen}
            userId={userId}
            role={role}
          />
        ) : (
          <EditTechnicianModal
            isOpen={isEditAccountOpen}
            onClose={onEditAccountClose}
            onRemoveOpen={onRemoveAccountOpen}
            technicianId={userId}
          />
        ))}
      {isRemoveAccountOpen && (
        <ConfirmRemovalDialog
          isOpen={isRemoveAccountOpen}
          onClose={onRemoveAccountClose}
          title={
            <Flex alignItems={'center'}>
              <IconWrapperStyled>
                <RoleIcon role={role} />
              </IconWrapperStyled>
              <span>Remove {getRoleDisplayName(role)}</span>
            </Flex>
          }
          itemTitle={`${getRoleDisplayName(role)} ${fullName}`}
          handleOnClick={onAccountRemove}
          loading={deleteAccountLoading}
        />
      )}
    </Box>
  )
}

export default AccountEdit
