import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'

import { Center, Grid } from '@chakra-ui/react'

interface IProps {
  handleOnEdit: () => void
  handleOnDelete?: () => void
}

export default function EditRemoveButton({
  handleOnEdit,
  handleOnDelete,
}: IProps) {
  return (
    <Grid
      h='full'
      maxW='100%'
      ml='10px'
      gridTemplateRows={handleOnDelete ? '1fr 1fr' : '1fr'}
      gap='10px'
    >
      <Center
        cursor='pointer'
        h='full'
        w='60px'
        bgColor='#E5E8EE'
        rounded='5px'
        boxShadow='0 2px 7px 0 rgba(50,121,199,0.5)'
        transition='ease-in-out'
        transitionProperty='background-color'
        transitionDuration='300ms'
        _hover={{
          bgColor: '#d2d2d4',
        }}
        onClick={handleOnEdit}
        data-testid='EditRemoveButton:edit'
      >
        <HiOutlinePencil color='#3279C7' size='24px' />
      </Center>
      {handleOnDelete && (
        <Center
          cursor='pointer'
          h='full'
          w='60px'
          bgColor='#D01030'
          rounded='5px'
          boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
          transition='ease-in-out'
          transitionProperty='background-color'
          transitionDuration='300ms'
          _hover={{
            bgColor: '#b40e2a',
          }}
          onClick={handleOnDelete}
          data-testid='EditRemoveButton:delete'
        >
          <HiOutlineTrash color='#fff' size='24px' />
        </Center>
      )}
    </Grid>
  )
}
