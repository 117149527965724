import { HYDRATE } from 'next-redux-wrapper'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Notification } from '@/graphql/appsync/types'

import type {
  AppState,
  /*, AppThunk*/
} from '../store'

export interface NotificationState {
  notifications: Notification[]
  notificationCount: number | undefined
}

const initialState: NotificationState = {
  notifications: [],
  notificationCount: undefined,
}

export const incidentSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload
    },
    setNotificationCount: (state, action: PayloadAction<number>) => {
      state.notificationCount = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      console.log('HYDRATE', state, action.payload)
      return {
        ...state,
        ...action.payload.subject,
      }
    },
  },
})

export const { setNotifications, setNotificationCount } = incidentSlice.actions

export const selectNotifications = (state: AppState) =>
  state.notification?.notifications

export const selectNotificationCount = (state: AppState) =>
  state.notification?.notificationCount

export default incidentSlice.reducer
