import {
  DEFAULT_ROLE,
  IAccount,
  IAccountGroups,
  Role,
  RoleExtended,
} from '../types/types.d'

/**
 * Filter account based on full name of the user using filter phrase.
 */
export const filterAccounts = (
  accounts: IAccount[],
  filter: string
): IAccount[] =>
  accounts.filter(
    (account) =>
      `${account.firstName} ${account.lastName}`
        .toLocaleLowerCase()
        .includes(filter.toLocaleLowerCase()) || filter === ''
  )

/**
 * Take account list and split it into groups according to assigned role.
 * Here three origial roles (Role) are mapped into useful extended roles
 * (RoleExtended).
 */
export const groupAccounts = (accounts: IAccount[]): IAccountGroups => {
  const groupsMapInitial: { [key in RoleExtended]: IAccount[] } = {
    [RoleExtended.PROGRAM_MANAGER]: [],
    [RoleExtended.OPERATOR]: [],
    [RoleExtended.GUARD]: [],
    [RoleExtended.EMPLOYEE]: [],
    [RoleExtended.TECHNICIAN]: [],
    [RoleExtended.IMPLEMENTER]: [],
  }
  const groupsMap = accounts.reduce((groups, account) => {
    if (account.roleName in RoleExtended) {
      groups[account.roleName].push(account)
    } else if (account.roleName === DEFAULT_ROLE) {
      // users with role 'default-roles-hivewarchdev.com' are in fact operators
      groups[RoleExtended.OPERATOR].push({
        ...account,
        roleName: RoleExtended.OPERATOR,
      })
    } else if (account.roleName === '') {
      groups[RoleExtended.EMPLOYEE].push(account)
    }
    return groups
  }, groupsMapInitial)
  return Object.entries(groupsMap).map(
    ([role, accounts]: [RoleExtended, IAccount[]]) => ({
      role,
      accounts,
    })
  )
}

export const getPascalCaseRole = (role: RoleExtended) => {
  switch (role) {
    case RoleExtended.PROGRAM_MANAGER:
      return 'ProgramManager'
    case RoleExtended.OPERATOR:
      return 'Operator'
    case RoleExtended.GUARD:
      return 'Guard'
    case RoleExtended.EMPLOYEE:
      return 'Employee'
    case RoleExtended.TECHNICIAN:
      return 'Technician'
    case RoleExtended.IMPLEMENTER:
      return 'Implementer'
    default:
      return ''
  }
}

/**
 * This checks if a user has permission to add/edit/delete the specified role
 * crudRole - the role to perform the CRUD operation on
 * userRole - the users role
 */
export const hasCRUDPermission = (
  crudRole: Role | RoleExtended,
  userRole: Role
) => {
  if (userRole === Role.IMPLEMENTER) {
    return true
  } else if (
    userRole === Role.PROGRAM_MANAGER &&
    crudRole !== Role.IMPLEMENTER
  ) {
    return true
  }
  return false
}
