import { CountUp } from 'use-count-up'

export default function TimeFormatCountUp({ value }: { value: number }) {
  const SECONDS_IN_MINUTE = 60
  if (value < SECONDS_IN_MINUTE) {
    return (
      <>
        <CountUp duration={1} isCounting end={value} key={value} />s
      </>
    )
  }

  const minutes = Math.floor(value / SECONDS_IN_MINUTE)
  const seconds = value % SECONDS_IN_MINUTE
  return (
    <>
      <CountUp duration={1} isCounting end={minutes} key={minutes} />m{' '}
      <CountUp duration={1} isCounting end={seconds} key={seconds} />s
    </>
  )
}
