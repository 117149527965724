import { useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { HiArrowLeft } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
} from '@chakra-ui/react'

import { Button, FormInputSelectControl } from '@/components/ui'
import { IncidentsOwnerToggle } from '@/features/incidents-table'
import {
  useFacilityNamesQuery,
  useFloorsByFacilityIdsQuery,
} from '@/graphql/generated/hooks'
import {
  selectIncidentCreatedAtFilter,
  selectIncidentDeviceTypeFilter,
  selectIncidentFacilityIdsFilter,
  selectIncidentFloorsIdsFilter,
  selectIncidentPriorityFilter,
  selectIncidentStatusFilter,
  setIncidentCreatedAtFilter,
  setIncidentDeviceTypeFilter,
  setIncidentFacilityIdsFilter,
  setIncidentFloorsIdsFilter,
  setIncidentPriorityFilter,
  setIncidentStatusFilter,
} from '@/redux/ui/uiSlice'
import { mixpanel } from '@/utils/analytics'
import { createdAtOptions, getRelatedFloors } from '@/utils/filterDrawers'
import { getFacilityOptions } from '@/utils/forms/selectOptions'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const incidentStatusOptions = [
  { value: 'ACTIVE', label: 'Active', color: '#D01030' },
  { value: 'IN_PROGRESS', label: 'In Progress', color: '#D16A1E' },
  { value: 'RESOLVED', label: 'Resolved', color: '#29D11E' },
]

const deviceTypeOptions = [
  { value: 'CAMERA', label: 'Camera' },
  { value: 'DOOR', label: 'Access Control Door' },
]

const priorityOptions = [
  { value: 'HIGH', label: 'High', color: '#d01030' },
  { value: 'MEDIUM', label: 'Medium', color: '#FFC400' },
  { value: 'LOW', label: 'Low', color: '#36B37E' },
]

export default function IncidentsFilterDrawer({ isOpen, onClose }: IProps) {
  const dispatch = useDispatch()
  const { data: facilitiesData } = useFacilityNamesQuery({
    fetchPolicy: 'network-only',
  })
  const { control, handleSubmit, watch, setValue } = useForm({
    reValidateMode: 'onChange',
  })
  const facilityIdsFilterValue = useSelector(selectIncidentFacilityIdsFilter)
  const floorsIdsFilterValue = useSelector(selectIncidentFloorsIdsFilter)
  const createdAtFilterValue = useSelector(selectIncidentCreatedAtFilter)
  const incidentStatusFilterValue = useSelector(selectIncidentStatusFilter)
  const incidentDeviceTypeFilterValue = useSelector(
    selectIncidentDeviceTypeFilter
  )
  const incidentPriorityFilterValue = useSelector(selectIncidentPriorityFilter)
  const watchFacility = watch('facilityName', facilityIdsFilterValue)
  const watchFloor = watch('floorName')
  const ids: string[] = facilityIdsFilterValue?.map((e) => e.value as string)
  const { data: floorsData, refetch } = useFloorsByFacilityIdsQuery({
    fetchPolicy: 'network-only',
    variables: { ids: ids },
  })

  const facilityOptions = getFacilityOptions(facilitiesData)
  const floorsOptions =
    floorsData?.floors?.edges.map((f) => ({
      label: `${f?.node?.name} - (${f?.node?.facility?.name})`,
      value: f?.node?.id,
    })) || []
  const validFloorOptions = watchFacility?.length ? floorsOptions : []

  const onSubmit = async (values) => {
    const selectedIncidentFacilityIds = values?.facilityName
    const selectedIncidentFloorsIds = values?.floorName
    const selectedCreatedAtRange = values?.createdAt
    const selectedIncidentStatus = values?.incidentStatus
    const selectedDeviceTypes = values?.deviceType
    const selectedPriorities = values?.priority
    dispatch(
      setIncidentFacilityIdsFilter(
        selectedIncidentFacilityIds?.length ? selectedIncidentFacilityIds : null
      )
    )
    dispatch(
      setIncidentFloorsIdsFilter(
        selectedIncidentFloorsIds?.length ? selectedIncidentFloorsIds : null
      )
    )
    dispatch(setIncidentCreatedAtFilter(selectedCreatedAtRange || null))
    dispatch(
      setIncidentStatusFilter(
        selectedIncidentStatus?.length ? selectedIncidentStatus : null
      )
    )
    dispatch(setIncidentDeviceTypeFilter(selectedDeviceTypes || null))
    dispatch(
      setIncidentPriorityFilter(
        selectedPriorities?.length ? selectedPriorities : null
      )
    )
    onClose()

    Object.keys(values).forEach((key) => {
      //delete null values and empty arrays
      if (values[key] === null || values[key].length == 0) {
        delete values[key]
      }
    })

    mixpanel.track('Incidents View: Filters Applied', {
      filters: values,
    })
  }

  useEffect(() => {
    if (watchFacility && watchFacility[0]?.value) {
      const ids = watchFacility.map((e) => e.value)
      refetch({ ids })
    }

    if (watchFacility && watchFloor) {
      // Set the floor values with only values from selected facilities
      const validFloors = getRelatedFloors(watchFacility, watchFloor)
      setValue('floorName', validFloors)
    }
  }, [facilityIdsFilterValue, watchFacility])

  return (
    <Drawer size='sm' isOpen={isOpen} placement='right' onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader
          d='flex'
          minH='65px'
          py='0'
          px='5'
          borderColor='#D5DCE4'
          borderBottomWidth='1px'
          alignItems='center'
        >
          <HStack spacing='3'>
            <IconButton
              aria-label='Back button'
              variant='ghost'
              h='30px'
              w='30px'
              minWidth='30px'
              borderRadius='4px'
              onClick={onClose}
              icon={<HiArrowLeft size={18} />}
            />
            <Box letterSpacing='-0.53px' fontSize='20px' fontWeight='bold'>
              Filter Incidents
            </Box>
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          <Box mt='6' mb='3'>
            <IncidentsOwnerToggle />
          </Box>
          <Box mt='6' mb='3'>
            <FormInputSelectControl
              id='facilityName'
              label='Facility Name'
              placeholder='Select Facility(-ies)'
              closeMenuOnSelect={false}
              isMulti
              isSearchable
              isClearable
              defaultValue={
                facilityIdsFilterValue?.length ? facilityIdsFilterValue : null
              }
              control={control}
              options={facilityOptions}
              dataTestId='incidentsPage_filterDrawer_facilityName'
            />
          </Box>
          <Box mb='3'>
            <FormInputSelectControl
              id='floorName'
              label='Floor Name'
              placeholder='Select Floor(s)'
              closeMenuOnSelect={false}
              isMulti
              isSearchable
              isClearable
              defaultValue={
                floorsIdsFilterValue?.length ? floorsIdsFilterValue : null
              }
              control={control}
              options={validFloorOptions}
              dataTestId='incidentsPage_filterDrawer_floorName'
            />
          </Box>
          <Box mb='3'>
            <FormInputSelectControl
              id='createdAt'
              label='Created At'
              placeholder='Select Time Range'
              isClearable
              isSearchable={false}
              defaultValue={createdAtFilterValue || null}
              control={control}
              options={createdAtOptions}
              dataTestId='incidentsPage_filterDrawer_createdAt'
            />
          </Box>
          <Box mb={3}>
            <FormInputSelectControl
              id='incidentStatus'
              label='Incident Status'
              placeholder='Select Incident Status(-es)'
              closeMenuOnSelect={false}
              isMulti
              isSearchable
              isClearable
              defaultValue={
                incidentStatusFilterValue?.length
                  ? incidentStatusFilterValue
                  : null
              }
              control={control}
              options={incidentStatusOptions}
              dataTestId='incidentsPage_filterDrawer_incidentStatus'
            />
          </Box>
          <Box mb={3}>
            <FormInputSelectControl
              id='deviceType'
              label='Device Type'
              placeholder='Select Device Type'
              closeMenuOnSelect={false}
              isSearchable
              isClearable
              defaultValue={incidentDeviceTypeFilterValue || null}
              control={control}
              options={deviceTypeOptions}
              dataTestId='incidentsPage_filterDrawer_deviceType'
            />
          </Box>
          <Box mb={3}>
            <FormInputSelectControl
              id='priority'
              label='Priority'
              placeholder='Select Priority(-ies)'
              closeMenuOnSelect={false}
              isMulti
              isSearchable
              isClearable
              defaultValue={
                incidentPriorityFilterValue?.length
                  ? incidentPriorityFilterValue
                  : null
              }
              control={control}
              options={priorityOptions}
              dataTestId='incidentsPage_filterDrawer_priority'
            />
          </Box>
        </DrawerBody>
        <DrawerFooter borderColor='#D5DCE4' borderTopWidth='1px'>
          <Button
            isFullWidth
            onClick={handleSubmit(onSubmit)}
            data-testid='incidentsPage_filterDrawer_applyButton'
          >
            Apply Filters
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
