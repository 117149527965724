import Employee from '@/components/icons/Employee'
import Guards from '@/components/icons/Guards'
import Operator from '@/components/icons/Operator'
import ProgramManager from '@/components/icons/ProgramManager'
import Technician from '@/components/icons/Technician'

import { Role, RoleExtended } from '../types/types.d'

const RoleIcon = ({ role }: { role: RoleExtended }) => {
  switch (role) {
    case RoleExtended.GUARD:
      return <Guards strokeColor='#000' className='icon' />
    case RoleExtended.OPERATOR:
      return <Operator strokeColor='#000' className='icon' />
    case RoleExtended.PROGRAM_MANAGER:
      return <ProgramManager strokeColor='#000' className='icon' />
    case RoleExtended.EMPLOYEE:
      return <Employee strokeColor='#000' className='icon' />
    case RoleExtended.TECHNICIAN:
      return <Technician strokeColor='#000' className='icon' />
    default:
      return null
  }
}

const getRoleDisplayName = (role: RoleExtended | Role) => {
  switch (role) {
    case RoleExtended.GUARD:
      return 'Guard'
    case RoleExtended.OPERATOR:
      return 'Operator'
    case RoleExtended.PROGRAM_MANAGER:
      return 'Program Manager'
    case RoleExtended.EMPLOYEE:
      return 'Employee'
    case RoleExtended.TECHNICIAN:
      return 'Technician'
    case RoleExtended.IMPLEMENTER:
      return 'Implementer'
    default:
      return ''
  }
}

export { RoleIcon, getRoleDisplayName }
