import React from 'react'

import { CallStatus, useCall } from '../hooks/useCall'
import { CgCloseO, CgMinimizeAlt } from 'react-icons/cg'

import { Box } from '@chakra-ui/react'

import { CallActionIcon } from './CallActionIcon'
import { CallEnded } from './CallEnded'
import { CallHeader } from './CallHeader'
import { CallLive } from './CallLive'

export const CallExpanded = ({ callStatus }: { callStatus: CallStatus }) => {
  const { callDuration, closeCall, minimizeCall } = useCall()

  return (
    <Box
      w='410px'
      height={'100%'}
      flex={1}
      display='flex'
      flexDirection='column'
    >
      <Box display='flex' w='410px' alignItems={'center'}>
        <CallHeader
          title={'Local Law Enforcement'}
          subtitle={callStatus === CallStatus.Ended ? 'Call Ended' : 'Live'}
          duration={callDuration}
        />
        {callStatus === CallStatus.Ended ? (
          <CallActionIcon
            label={'Close'}
            onClick={closeCall}
            icon={<CgCloseO size={24} data-testid='CallClose:icon' />}
          />
        ) : (
          <CallActionIcon
            label={'Collapse'}
            onClick={minimizeCall}
            icon={<CgMinimizeAlt size={24} data-testid='CallExpanded:icon' />}
          />
        )}
      </Box>
      <Box mt='20px' ml='-20px' w='450px' h='2px' bgColor='#e2e8f0'></Box>
      {callStatus === CallStatus.Ended ? <CallEnded /> : <CallLive />}
    </Box>
  )
}
